import * as React from "react";
// import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

export const NewBlock = () => {
  //some
  return (
    <>
      <Grid
        direction='row'
        justifyContent='center'
        alignItems='center'
        container
        columns={12}
      >
        <Grid item xs={1} md={3} lg={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItem: "flex-end",
            }}
          >
            <PopUps2 />
          </Box>
        </Grid>
        <Grid item xs={5} md={4} lg={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "flex-start",
            }}
          >
            <img width='270px' src='/images/oval.png' alt='' />
          </Box>
        </Grid>
        <Grid item xs={6} md={5} lg={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItem: "flex-start",
            }}
          >
            <PopUps3 />
            <PopUps />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export const PopUps = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#308f96",
        borderRadius: 1,
        ml: 2,
        p: 2,
        width: "80%",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant='subtitle2' sx={{ color: "#ffff" }}>
          What will this be like in ten or twenty years’ time?
        </Typography>
      </Box>
    </Box>
  );
};

export const PopUps2 = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#308f96",
        borderRadius: 1,
        ml: 2,
        p: 2,
        width: "70%",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant='subtitle2' sx={{ color: "#ffff" }}>
          What will I never see again?
        </Typography>
      </Box>
    </Box>
  );
};

export const PopUps3 = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#308f96",
        borderRadius: 2,
        ml: 1,
        p: 1,
        m: 3,
        translate: "-80px -100px",
        width: "80%",
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant='subtitle2' sx={{ color: "#ffff" }}>
          What will I remember, what will I forget?
        </Typography>
      </Box>
    </Box>
  );
};

export default function Dev() {
  return (
    <Grid item xs={12}>
      <NewBlock />
    </Grid>
  );
}
